import React from "react"
import { StoryGroup } from "components"
import { flatStoryGroup } from "utils/array-util"

const LinkMap = {
  "Main Thread": "4c8d34713246",
  "Web Worker": "4c8d34713246",
  "Service Worker": "4c8d34713246",
  Compositor: "4c8d34713246",
  "Rendering Engine2": "4c8d34713246",
  "JS Engine": "4c8d34713246",

  Rendering: "35567efa8e38",
  "Static vs Dynamic Web": "35567efa8e38",
  "Time to First Byte": "35567efa8e38",
  "First Paint": "35567efa8e38",
  "First Contentful Paint": "35567efa8e38",
  "Time To Interactive": "35567efa8e38",
  "Rendering Techniques": "35567efa8e38",
  SSR: "35567efa8e38",
  CSR: "35567efa8e38",
  "Static Rendering2": "35567efa8e38",
  "Hybrid Rendering2": "35567efa8e38",

  "Client Side Rendering": "777c64a53f59",
  "Server Side Rendering": "777c64a53f59",
  "Pre Rendering": "777c64a53f59",
  "Static Rendering": "777c64a53f59",
  "Static Side Generator": "777c64a53f59",

  "SSR w/hydration": "12775d08c111",
  Streaming: "12775d08c111",
  "Progressive Hydration": "12775d08c111",
  "Static Generation": "12775d08c111",
  "Incremantral Static Generation": "12775d08c111",
}

const storiesRendering = [
  {
    title: "Chrome Rendering",
    postId: "4c8d34713246",
  },
  {
    title: "Web Rendering",
    postId: "35567efa8e38",
  },
  {
    title: "HTML Rendering Nerede Yapılmalı ?",
    postId: "777c64a53f59",
    postIdEnglish: "ce4162ed3de8",
  },
  {
    title: "Web Rendering - Updated",
    postId: "12775d08c111",
  },
  {
    title: "RSC (React Server Components)",
    postId: "6357924b1125",
  },
  {
    title: "Rendering Patterns & Frameworks",
    postId: "315eaffe2b0b",
  },
]

const storiesHydration = [
  {
    title: "Next, Remix, Qwik, Astro, Vite, …",
    postId: "64c9b93bc2e0",
  },
  {
    title: "Rendering, Hydration, Resumability",
    postId: "b5bfba2d068c",
  },
  {
    title: "Hydration (Island Architecture)",
    postId: "d38be92d5fa1",
  },
  {
    title: "React 18 Suspense, Streaming, Partial Hydration",
    postId: "478a4d547ea3",
  },
  {
    title: "Verimli Hydration Neden Zor ?",
    postId: "960ce359333a",
  },
  {
    title: "Remix (Progressive Enhancement)",
    postId: "ea5d3a644e3f",
  },
]

const storiesGroup = [
  {
    title: "Rendering",
    storyPart: storiesRendering,
  },
  {
    title: "Hydration",
    storyPart: storiesHydration,
  },
]

export const context = {
  stories: flatStoryGroup(storiesGroup),
  linkMap: LinkMap,
  title: "Rendering Teknikleri",
  path: "rendering-techniques",
}

const RenderingTechniquesPage = () => {
  return (
    <StoryGroup
      title={context.title}
      complex={true}
      stories={storiesGroup}
      linkMap={context.linkMap}
    />
  )
}

export default RenderingTechniquesPage
